import { Avatar } from "@chakra-ui/avatar"
import React from "react"
import TitledInput from "../shared/titled_input"
import ZiptiContainer from "../shared/zipti_container"
import ZiptiText from "../shared/zipti_text"

interface UserProfileCardProps {
  name: string
  email: string
  profileImageUrl: string
  changeCopy: string
}

const UserProfileCard: React.FC<UserProfileCardProps> = props => {
  return (
    <ZiptiContainer h="50vh" position="relative">
      <Avatar
        src={props.profileImageUrl}
        position="absolute"
        top="5vh"
        h="10vh"
        w="10vh"
        borderRadius="full"
        borderColor="#4329AE"
        borderStyle="solid"
        borderWidth="3px"
      />
      <ZiptiText
        fontSize="1.5vh"
        fontWeight="bold"
        position="absolute"
        top="16vh"
        cursor="pointer"
      >
        {props.changeCopy}
      </ZiptiText>
      <TitledInput
        position="absolute"
        top="25vh"
        label="Name"
        placeholder={props.name}
      />
      <TitledInput
        position="absolute"
        top="35vh"
        label="Email"
        placeholder={props.email}
      />
    </ZiptiContainer>
  )
}

export default UserProfileCard
