import React from "react"
import Wrapper from "../shared/wrapper"
import ZiptiText from "../shared/zipti_text"
import UserProfileCard from "./user_profile_card"

interface EditUserProfileProps {
  yourInfoCopy: string
}

const EditUserProfile: React.FC<EditUserProfileProps> = props => {
  return (
    <Wrapper
      footerProps={{ bgColor: "gray.100", mt: "10vh" }}
      bgColor="gray.100"
    >
      <ZiptiText mt="5vh" mb="-3vh" fontWeight="bold" fontSize="2.5vh">
        {props.yourInfoCopy}
      </ZiptiText>
      <UserProfileCard />
    </Wrapper>
  )
}

export default EditUserProfile
